import React from 'react';
import { platforms } from './data';
import Button from '../Button';
import DownloadButton from '../DownloadButton';
import vgLogo from '../../assets/images/logo-2x.png';
import { ClientVersion } from '../../utils/clientVersion';
import * as s from './s.module.css';

class Download extends React.Component {
  state = {
    clientVersion: '1.0.0',
  }

  componentDidMount() {
    this.getCurrentClientVersion();
  }

  async getCurrentClientVersion() {
    const currentVersion = await ClientVersion.currentVersion();
    if (currentVersion) {
      this.setState({
        clientVersion: currentVersion,
      });
    }
  }

  render() {
    const { clientVersion } = this.state;
    return (
      <section className={s.downloadContainer}>
        <div className={s.downloadContent}>
          <div className={s.row}>
            <Button to="/">
            <img className={s.downloadLogo} src={vgLogo} />
            </Button>
          </div>
          <div className={s.row}>
            <div className={s.titleContainer}>
              <h2 className={s.title}>Virtue Poker</h2>
            </div>
          </div>
          <div className={s.row}>
            <DownloadButton />
          </div>
          <div className={s.row}>
            <div className={s.platformRequirementsContainer}>
              {platforms.map((platform, idx) => (
                <div key={idx} className={s.platformRequirements}>
                  <div className={s.requirementsIconContainer}>
                    {platform.icon}
                  </div>
                  <span className={s.platformTitle}>{platform.os}</span>
                  <a className={s.downloadLink} download href={platform.url.replace('%version%', clientVersion)}>
                    Download
                  </a>
                  <div className={s.requirementsContainer}>
                    <span>
                      Recommended system requirements for {platform.hardware}
                    </span>
                    {platform.versions.map((version, idx) => (
                      <span key={idx} className={s.requirementItem}>{version} or later</span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Download;
