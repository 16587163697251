import React from 'react';
import config from '../../config';
import { AppleLogoIcon, WindowsLogoIcon, LinuxLogoIcon } from "../Icon";

export const platforms = [{
  id: "mac",
  os: "Mac OS",
  hardware: "Mac",
  versions: ["Mac OS X 10.9"],
  icon: <AppleLogoIcon />,
  url: config.desktopAppUrl.mac,
}, {
  id: "windows",
  os: "Windows",
  hardware: "PC",
  versions: ["Windows 7"],
  icon: <WindowsLogoIcon />,
  url: config.desktopAppUrl.windows,
}, {
  id: "linux",
  os: "Linux",
  hardware: "Linux",
  versions: [
    "Ubuntu LTS releases 18.04",
    "Fedora 26",
    "Red Hat Enterprise Linux 7.0"
  ],
  icon: <LinuxLogoIcon />,
  url: config.desktopAppUrl.linux,
}]