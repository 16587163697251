export enum OSType {
  MAC,
  WINDOWS,
  LINUX,
}

export class OSDetector {
  public static detect() {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let detectedOs: OSType = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      detectedOs = OSType.MAC
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      detectedOs = OSType.WINDOWS;
    } else if (/Linux/.test(platform)) {
      detectedOs = OSType.LINUX;
    }

    return detectedOs;
  }
}