// extracted by mini-css-extract-plugin
export var downloadContainer = "s-module--downloadContainer--b0421";
export var downloadContent = "s-module--downloadContent--bd989";
export var downloadLink = "s-module--downloadLink--9c735";
export var downloadLogo = "s-module--downloadLogo--190bb";
export var platformRequirements = "s-module--platformRequirements--103cd";
export var platformRequirementsContainer = "s-module--platformRequirementsContainer--6ebf0";
export var platformTitle = "s-module--platformTitle--dac82";
export var requirementItem = "s-module--requirementItem--96335";
export var requirementsContainer = "s-module--requirementsContainer--ae7d0";
export var requirementsIconContainer = "s-module--requirementsIconContainer--1368e";
export var row = "s-module--row--53379";
export var subtitle = "s-module--subtitle--f4844";
export var title = "s-module--title--c0a7e";
export var titleContainer = "s-module--titleContainer--54ab5";