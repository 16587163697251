import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import config from '../../config';
import { OSDetector, OSType } from '../../utils/os';
import { ClientVersion } from '../../utils/clientVersion';
import { Download } from '../Icon';
import { isDesktop } from '../../utils/deviceDetector';
import * as s from './s.module.css';

interface Props {
  className?: string,
  icon?: Object,
  showOnDesktopOnly?: boolean,
  showAsLink?: any,
  isAnimated: boolean,
}

interface State {
  os: OSType
  clientVersion: string,
  isDesktop: boolean,
}

class DownloadButton extends React.Component<Props, State> {
  state = {
    os: undefined,
    clientVersion: '1.0.0',
    isDesktop: undefined,
  }

  componentDidMount() {
    this.getCurrentClientVersion();

    const os = OSDetector.detect();

    this.setState({
      os,
      isDesktop: window ? isDesktop() : undefined,
    });
  }

  async getCurrentClientVersion() {
    const currentVersion = await ClientVersion.currentVersion();
    if (currentVersion) {
        this.setState({
            clientVersion: currentVersion,
        });
    }
  }

  get href() {
    const { os, clientVersion } = this.state;

    if (os === OSType.MAC) {
      return config.desktopAppUrl.mac.replace('%version%', clientVersion);
    } else if (os === OSType.WINDOWS) {
      return config.desktopAppUrl.windows.replace('%version%', clientVersion);
    } else if (os === OSType.LINUX) {
      return config.desktopAppUrl.linux.replace('%version%', clientVersion);
    }
  }

  render() {
    const { className, icon, showOnDesktopOnly = false, showAsLink = false, isAnimated = false } = this.props;
    const { isDesktop } = this.state;

    if (showOnDesktopOnly && isDesktop !== undefined && !isDesktop) {
      return null;
    }
    if (showAsLink) {
      return (
        <a href={this.href} target='_'>
        <span>{showAsLink}</span>
      </a>
      )
    }
    return (
      <OutboundLink href={this.href} className={cx(s.button, className, { [s.animation]: isAnimated } )} download>
        {icon || <Download />}
        <span>Download</span>
      </OutboundLink>
    );
  }
}

export default DownloadButton;
