import axios from 'axios';
import config from '../config';

export class ClientVersion {
  public static async currentVersion() {
    try {
      const response = await axios.get(config.desktopAppUrl.clientVersionFile, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      });
      if (response.status === 200 && response.data && response.data.currentVersion) {
        return response.data.currentVersion;
      }
    } catch (err) {
      console.log('Current client version cannot be read', err);
    }
    return '1.0.0';
  }
}
