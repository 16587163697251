import React from "react";
import Seo from "../components/seo";
import Download from '../components/Download';

const DownloadPage = () => (
    <>
        <Seo title="Download" />
        <Download />
    </>
)

export default DownloadPage;
